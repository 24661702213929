import React from "react"
import ReactMarkdown from "react-markdown"

import styles from "./styles.module.css"

export default ({ extraImage, content, externalLink, className }) => (
  <section className={`${styles.page} ${styles[className]}`}>
    <div className={styles.content}>
      <div className={styles.text}>
        <ReactMarkdown source={content} className={styles.markdown} />
        {extraImage && (
          <img src={extraImage} alt="" className={styles.extraImage} />
        )}
      </div>
      <a href={externalLink} target="_blank" rel="noopener noreferrer">
        <button className={styles.button}>Visite o nosso site</button>
      </a>
    </div>
  </section>
)
