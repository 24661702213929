import React from "react"
import ReactMarkdown from "react-markdown"

import styles from "./styles.module.css"

export default ({
  headerImage,
  productLogo,
  features,
  className,
  posHeaderImahe,
}) => (
  <div
    style={{ backgroundImage: `url(${headerImage})` }}
    className={`${styles.header} ${styles[className]}`}
  >
    <div className={styles.overlay} />
    <div className={styles.content}>
      <div className={styles.row}>
        <img
          className={styles.productLogo}
          src={productLogo}
          alt={className}
          loading="eager"
        />
        {posHeaderImahe && posHeaderImahe.showImage && (
          <img
            className={styles.ctaPos}
            src={posHeaderImahe.image.childImageSharp.fixed.src}
            alt="POS já disponível"
          />
        )}
      </div>
      <div className={styles.row}>
        {(features || []).map(feature => (
          <div key={feature.title} className={styles.feature}>
            <div className={styles.icon}>
              <img
                src={feature.icon && feature.icon.childImageSharp.fixed.src}
                alt={`${feature.title} icon`}
                loading="eager"
              />
            </div>
            <h3>{feature.title}</h3>
            {feature.extra && (
              <div className={styles.extra}>
                <ReactMarkdown source={feature.extra} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
)
