import React from "react"

import SimpleLayout from "../layouts/SimpleLayout"
import SEO from "../components/seo"
import HeroSection from "../components/ProductPage/HeroSection/HeroSection"
import TextSection from "../components/ProductPage/TextSection/TextSection"
import ImageTextSection from "../components/ProductPage/ImageTextSection/ImageTextSection"

import styles from "./styles/product.module.css"

const ProductPageComponent = ({
  seoTitle,
  seoDescription,
  seoImage,
  headerImage,
  productLogo,
  content,
  extraImage,
  featuresSections,
  features,
  isPreview,
  className,
  externalLink,
  url,
  posHeaderImahe,
  ...props
}) => (
  <SimpleLayout path={props.path} isPreview={isPreview}>
    <SEO
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      url={url}
    />
    <div className={styles[className]}>
      <HeroSection
        headerImage={headerImage}
        productLogo={productLogo}
        features={features}
        className={className}
        posHeaderImahe={posHeaderImahe}
      />
      <TextSection
        content={content}
        extraImage={extraImage}
        externalLink={externalLink}
        className={className}
      />
      {(featuresSections || []).map((section, i) => (
        <ImageTextSection
          section={section}
          i={i}
          key={i}
          className={className}
        />
      ))}
      }
      {!!(features || []).length && (
        <section className={styles.nav}>
          <div className={styles.content}>
            <div className={styles.row}>
              {features.map(feat => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={feat.link}
                  className={styles.navItem}
                  key={feat.title}
                >
                  {feat.title}
                </a>
              ))}
            </div>
          </div>
        </section>
      )}
      }
    </div>
  </SimpleLayout>
)

export default ProductPageComponent
