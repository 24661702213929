import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ProductPageComponent from "../page-components/ProductPageComponent"

const imageSrcFormatter = (imageSrc, type = "fixed") =>
  (imageSrc &&
    imageSrc.childImageSharp &&
    imageSrc.childImageSharp[type] &&
    imageSrc.childImageSharp[type].src) ||
  ""

const query = graphql`
  query {
    page: markdownRemark(fields: { slug: { eq: "/pages/toc-online/" } }) {
      frontmatter {
        seoTitle
        seoDescription
        seoImage {
          childImageSharp {
            fixed(width: 480, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 92) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        content
        externalLink
        extraImage {
          childImageSharp {
            fixed(height: 200, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        productLogo {
          childImageSharp {
            fixed(height: 50, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        posHeaderImahe {
          showImage
          image {
            childImageSharp {
              fixed(height: 90, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        features {
          title
          link
          extra
          icon {
            childImageSharp {
              fixed(width: 80, quality: 92) {
                src
              }
            }
          }
        }
        featuresSections {
          title
          description
          link
          linkText
          image {
            childImageSharp {
              fluid(maxHeight: 400, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          # logo {
          #   childImageSharp {
          #     fluid(maxHeight: 50, quality: 92) {
          #       ...GatsbyImageSharpFluid
          #     }
          #   }
          # }
        }
      }
    }
  }
`
const TocOnlinePage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const {
        seoTitle,
        seoDescription,
        seoImage,
        headerImage,
        productLogo,
        content,
        extraImage,
        externalLink,
        features,
        featuresSections,
        posHeaderImahe,
      } = data.page.frontmatter

      return (
        <ProductPageComponent
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoImage={imageSrcFormatter(seoImage)}
          headerImage={imageSrcFormatter(headerImage, "fluid")}
          productLogo={imageSrcFormatter(productLogo)}
          content={content}
          extraImage={imageSrcFormatter(extraImage)}
          externalLink={externalLink}
          features={features}
          featuresSections={featuresSections}
          className="tocOnline"
          url="https://cloudware.pt/toconline"
          posHeaderImahe={posHeaderImahe}
          {...props}
        />
      )
    }}
  />
)

export default TocOnlinePage
