import React from "react"
import ReactMarkdown from "react-markdown"

import styles from "./styles.module.css"

export default ({ section, i, className }) => (
  <section
    className={`${styles.brandsSection} ${
      i % 2 === 0 ? styles.left : styles.right
    }  ${styles[className]}`}
  >
    <div className={styles.content}>
      <div className={styles.info}>
        <h4 className={styles.title}>{section.title}</h4>
        {section.logo && (
          <img
            src={section.image.childImageSharp.fluid.src}
            className={styles.logoImg}
            alt={section.title}
          />
        )}

        <ReactMarkdown
          className={styles.description}
          source={section.description}
        />
        {section.highlightedText && (
          <div
            className={styles.highlightedText}
            style={{ color: section.highlightedColor }}
          >
            <ReactMarkdown
              className={styles.description}
              source={section.highlightedText}
            />
          </div>
        )}
        {section.link && (
          <a
            className={styles.button}
            target="_blank"
            rel="noopener noreferrer"
            href={section.link}
          >
            {section.linkText}
          </a>
        )}
      </div>
      <div className={styles.image}>
        <img
          src={section.image && section.image.childImageSharp.fluid.src}
          alt=""
          className={styles.descriptionImage}
        />
      </div>
    </div>
  </section>
)
